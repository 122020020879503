import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { MediaLibraryData, MediaLibraryItem } from "../../../Types/MediaLibrary"

const initialState: MediaLibraryData = {
    items: [], selectedItem: {} as MediaLibraryItem,
    stats: { total_items: 0, total_size: 0 },
    filter: { query: '', tags: [], documents: true, images: true },
    isLoading: false
}


const MediaLibrarySlice = createSlice({
    name: "MediaLibrary",
    initialState: initialState,
    reducers: {
        setMediaLibraryData(state, action: PayloadAction<MediaLibraryData>) {
            state.items = action.payload.items
            state.selectedItem = action.payload.selectedItem
            state.stats = action.payload.stats
            state.filter = action.payload.filter
            state.isLoading = action.payload.isLoading
        },
    },
})
export const { setMediaLibraryData } = MediaLibrarySlice.actions
export default MediaLibrarySlice.reducer
