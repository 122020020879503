import React, { RefObject, useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import MainLayout from "../../Shared/Layout/MainLayout"
import ModalGroup from "../../Shared/Components/ModalGroup"
import Header from "../../Shared/Header/Header"
import Footer from "../../Shared/Layout/Footer"
import DonateButton from "../../Shared/Components/DonateButton"
import RoomDropdown from "../../Shared/Components/RoomDropdown"
import NewestMembersCard from "../../Shared/NewestMembers/NewestMemberCard"
import SponsorCard from "../../Shared/Components/SponsorCard"
import ChatCard from "../../Shared/Chat/ChatCard"
import { RoleType, StorageKey } from "../../../App/enums"
import OrganizationProfileCard from "../../Shared/Components/OrganizationProfileCard"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { RootState } from "../../../App/store"
import BackgroundWrapper from "../../Shared/Components/BackgroundWrapper"
import NewestOrganizationsCard from "../../Shared/NewestOrganizations/NewestOrganizations"
import { LoadingAnimation, SearchIcon } from "../../Shared/Components/SvgIcons"
import { not_found } from "../../../App/GlobaleVariables"
import ChatList from "./ChatList"
import ChatHeader from "../Components/ChatHeader"
import { CreateConversationPostRequest, chatGetByPartnerIdRequest, chatListGetRequest, singleChatListGetRequest } from "../ChatServiceRequest"
import { setActiveConversation, setConversationList, setHasMoreConversations, setHasUnseenMessages } from "../ChatSlice"
import { Conversation, ConversationMessage } from "../../../Types/Conversation"
import ChatContent from "../Components/ChatContent"
import ChatTextbox from "../Components/ChatTextbox"
import HTTPService from "../../../App/HTTPService"
import { headers } from "../../../App/Axios"
import SearchInput from "../../Shared/Primitive/SearchInput"
import { User } from "../../../Types/User"
import { GlobalSearchResult } from "../../../Types/GlobalSearchResultType"
import UserDataSlice from "../../Shared/SharedSlices/UserDataSlice"
import { socket } from "../../../Module/Shared/Header/Header"
import StorageService from "../../../App/StorageService"
import Image from "../../Shared/Primitive/Image"

function UserChatScreen() {
    const translation = useSelector(selectTranslations)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const donationLink = useSelector((state: RootState) => state.UserSettingsStatusSlice.userDonationLinkSlice.link)
    const activeConversation = useSelector((state: RootState) => state.chatSlice.activeConversationId)
    const conversations = useSelector((state: RootState) => state.chatSlice.conversations)
    const hasMoreConversations = useSelector((state: RootState) => state.chatSlice.hasMoreConversations)
    const [hasArticleCard, setHasArticleCard] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [webSocketReady, setWebSocketReady] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingSearchResults, setIsLoadingSearchResults] = useState(true)
    const [isSearching, setIsSearching] = useState(false)
    const [createdNewConversation, setCreatedNewConversation] = useState(false)
    const [sentMessage, SetsentMessage] = useState<ConversationMessage>()
    const [searchInputValue, setSearchInputValue] = useState("")
    const [searchedUsers, setSearchedUsers] = useState<GlobalSearchResult[]>([])
    const [chatStatusModifier, setChatStatusModifier] = useState("offline")
    let chatSocket = socket

    const searchUsersGetRequest = (searchQuery: string) => {
        return HTTPService.get(`/search/?query=${searchQuery}&page=1&result_type=users`, headers)
    }

    useEffect(() => {

        if (chatSocket == null || chatSocket.CLOSED) {
            const accessToken = StorageService.getItem(StorageKey.accessToken) as string
            let socketProtocol = window.location.host.startsWith('localhost') ? 'ws' : 'wss'
            chatSocket = new WebSocket(`${socketProtocol}://${window.location.hostname}${socketProtocol == 'ws' ? ':8000' : ''}/ws/chat/`, ["Token", accessToken])

        }

        chatSocket.onopen = event => {
            setWebSocketReady(true)
        }

        chatSocket.onclose = event => {
            setWebSocketReady(false)
        }

        chatSocket.onmessage = function (event) {
            let data = JSON.parse(event.data)
            switch (data.msg_type) {
                case "new.message":
                    let conv_id = data.data.conversation.id
                    if (conv_id == activeConversation) {
                        SetsentMessage(data.data)
                    } else {
                        let newList = conversations.map((item) => {
                            if (item.id == data.data.conversation.id) {
                                const updatedItem = {
                                    ...item,
                                    unseen_messages: item.unseen_messages + 1,
                                };

                                return updatedItem;
                            }

                            return item;
                        })

                        dispatch(setConversationList(newList))
                    }
                    break;
                default:
                    break;
            }
        }
    }, [chatSocket, activeConversation, conversations])


    const onRemoveIconClick = () => {
        setSearchInputValue("")
        setIsSearching(false)
    }

    useEffect(() => {
        let modifier = webSocketReady ? "online" : "offline"
        setChatStatusModifier(modifier)
    }, [webSocketReady, chatSocket])

    useEffect(() => {
        const getData = setTimeout(() => {
            if (searchInputValue.length > 2) {
                setIsLoadingSearchResults(true)
                setIsSearching(true)
                searchUsersGetRequest(searchInputValue).then(response => {
                    if (response.status === 200) {
                        setSearchedUsers(response.data.results)
                    }
                    setIsLoadingSearchResults(false)
                })
            } else {
                setIsSearching(false)
            }
        }, 500)
        return () => clearTimeout(getData)
    }, [searchInputValue])

    useEffect(() => {

        chatListGetRequest().then(response => {
            if (response.status === 200) {
                dispatch(setConversationList(response.data.results))
                dispatch(setHasMoreConversations(Boolean(response.data.next)))
            }
        })
        setIsLoading(false)
    }, [])

    const onChatClick = (userId: number) => {
        //Check if conversation with user exists
        let selectedConversation = null
        selectedConversation = conversations.find(conv => conv.member.some(member => member.user.id === userId))?.id || null

        //Test if conversation exists on the server
        if (selectedConversation == null) {

            chatGetByPartnerIdRequest(userId).then(response => {
                if (response.status === 200) {
                    dispatch(setConversationList([response.data].concat(conversations).sort((a: { updated_at: number }, b: { updated_at: number }) => b.updated_at - a.updated_at)))
                    dispatch(setActiveConversation(response.data.id))
                } else {
                    //Create new conversation
                    try {
                        CreateConversationPostRequest(userId, userDataState.id).then(conv => {
                            setIsSearching(false)
                            setSearchInputValue("")

                            if (conv.status === 201) {
                                singleChatListGetRequest(conv.data.id).then(response => {
                                    let convList = [...conversations, response.data]
                                    convList = convList.sort((a, b) => b.updated_at - a.updated_at)
                                    dispatch(setConversationList(convList))
                                    dispatch(setActiveConversation(conv.data.id))
                                })
                            }
                        })
                    } catch (e) {
                        console.log("ERROR CREATING NEW CONVERSATION")
                        console.log(e)
                    }
                }
            })
        }
    }

    const onMessageSent = (message: ConversationMessage) => {
        SetsentMessage(message)
        let newList = conversations.map((item) => {
            if (item.id == message.conversation.id) {
                const updatedItem = {
                    ...item,
                    updated_at: Date.now(),
                };

                return updatedItem;
            }

            return item;
        })
        newList = newList.sort((a, b) => b.updated_at - a.updated_at)

        dispatch(setConversationList(newList))
    }

    const onSeeAllEventsClick = () => {
        navigate(`/all-premium-events`)
    }

    return (
        <BackgroundWrapper>
            <Header />
            <MainLayout
                leftPanelFirstComponent={<OrganizationProfileCard />}
                leftPanelSecondComponent={<RoomDropdown type={RoleType.USER} />}
                leftPanelForthComponent={translation.newestOrganizations}
                leftPanelFifthComponent={<NewestOrganizationsCard />}
                RightPanelFirstComponent={donationLink === not_found ? <DonateButton /> : null}
                RightPanelSecondComponent={translation.newsMembersText}
                RightPanelThirdComponent={<NewestMembersCard />}
                RightPanelForthComponent={
                    <div className="main-layout__sponsored-title">
                        {translation.sponsoredText}
                        <div onClick={onSeeAllEventsClick} className="main-layout__sponsored-icon">
                            <SearchIcon color="dark-blue" />
                        </div>
                    </div>
                }
                RightPanelFifthComponent={<SponsorCard />}
                overflowModifier={true}
                isDashboad={hasArticleCard}
            >
                <div className="chat-screen">
                    {isLoading && <LoadingAnimation />}
                    <div className="chat-layout">
                        <div className={`chat-layout__left-panel ${activeConversation == 0 && "chat-layout__left-panel--visible"}`}>
                            <div className="chat-layout__left-panel-content">
                                <div className="chat-header-text">
                                    {translation.directChat}{" "}
                                    <span title={chatStatusModifier} className={`chat-status-dot chat-status-dot__${chatStatusModifier}`} />
                                </div>
                                <div className="chat-layout__search-container-outer">
                                    <div className="chat-layout__search-container">
                                        <SearchInput
                                            onRemoveIconClick={onRemoveIconClick}
                                            searchvalue={searchInputValue!}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setSearchInputValue(event.target.value)
                                            }}
                                            isGlobalSearch={false}
                                            isChatSearch={true}
                                        />
                                    </div>
                                </div>
                                {isSearching ? (
                                    <div className={`chat-layout__chatlist card ${searchedUsers.length === 0 && "chat-layout__center"} `}>
                                        <div className="chat-card__members">
                                            {searchedUsers.length === 0 && (
                                                <div className="chat-card-item chat-card-item--no-border">
                                                    <div className="chat-card-item">
                                                        <div className="chat-card-item__middle-panel">
                                                            <div className="chat-card-item__no-search-results">Keine Treffer</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {searchedUsers.map((item, index) => {
                                                return (
                                                    <div className="chat-card-item chat-card-item--no-border" onClick={() => onChatClick(item.id)}>
                                                        <div className="chat-card-item">
                                                            <div className="chat-card-item__left-panel">
                                                                {item.profile_photo?.url_path != null ? (
                                                                    <Image
                                                                        src={item.profile_photo.url_path}
                                                                        className="chat-card-item__image image__profile image__profile--fit image__profile--size-small"
                                                                    />
                                                                ) : (
                                                                    <Image
                                                                        src="/edusiia_placeholder_individual_profile.png"
                                                                        className="chat-card-item__image image__profile image__profile--fit image__profile--size-small"
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="chat-card-item__middle-panel">
                                                                <div className="chat-card-item__text">
                                                                    {item.first_name} {item.last_name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                ) : (
                                    <ChatList />
                                )}
                            </div>
                        </div>
                        <div className={`chat-layout__right-panel ${activeConversation == 0 && "chat-layout__right-panel--invisible"}`}>
                            {activeConversation > 0 && (
                                <>
                                    <ChatHeader />

                                    <ChatContent sentMsg={sentMessage} />

                                    <ChatTextbox onSendMessage={onMessageSent} />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </MainLayout>
            <Footer />
            <ModalGroup />
        </BackgroundWrapper>
    )
}

export default UserChatScreen
