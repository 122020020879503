import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"

/**
 * Fetches the media library statistics for a given room.
 *
 * @param {number} roomId - The unique identifier of the room.
 * @returns - A promise that resolves to the media library statistics.
 */
export const MediaLibraryStatsGetRequest = (roomId: number, orgId?: number) => {
    if (orgId) {
        return HTTPService.get(`/media-library/${roomId}/stats?org_id=${orgId}`, headers)
    } else {
        return HTTPService.get(`/media-library/${roomId}/stats`, headers)
    }
}


/**
 * Fetches media library files for a specific room.
 *
 * @param roomId - The ID of the room to fetch files for.
 * @param pageNumber - The page number of the files to fetch.
 * @param query - The search query to filter files.
 * @param documents - Flag to include documents in the results.
 * @param images - Flag to include images in the results.
 * @returns A promise that resolves to the response of the HTTP GET request.
 */
export const MediaLibraryFilesGetRequest = (roomId: number, pageNumber: number, query: string, documents: boolean, images: boolean, orgId?: number) => {
    if (orgId) {
        return HTTPService.get(`/media-library/${roomId}/files?page=${pageNumber}&query=${query}&documents=${documents}&images=${images}&org_id=${orgId}`, headers)
    } else {
        return HTTPService.get(`/media-library/${roomId}/files?page=${pageNumber}&query=${query}&documents=${documents}&images=${images}`, headers)
    }
}

/**
 * Sends a request to delete a file from the media library.
 *
 * @param fileId - The ID of the file to be deleted.
 * @returns A promise that resolves when the file is successfully deleted.
 */
export const MediaLibraryFileDeleteRequest = (fileId: number, orgId?: number) => {
    if (orgId) {
        return HTTPService.delete(`/media-library/file/${fileId}?org_id=${orgId}`, headers)
    } else {
        return HTTPService.delete(`/media-library/file/${fileId}`, headers)
    }
}

/**
 * Sends a PATCH request to update a file in the media library.
 *
 * @param {number} fileId - The ID of the file to be updated.
 * @param {FormData} formData - The form data containing the updated file information.
 * @returns {Promise} - A promise that resolves with the response of the HTTP request.
 */
export const MediaLibraryFilePatchRequest = (fileId: number, formData: FormData): Promise<any> => {
    return HTTPService.patch(`/media-library/file/${fileId}/`, formData, headers)
}