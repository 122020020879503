
import { useDispatch, useSelector } from 'react-redux';
import { MediaLibraryItem } from '../../../Types/MediaLibrary';
import { setMediaLibraryData } from '../../Shared/SharedSlices/MediaLibrarySlice';
import { RootState } from '../../../App/store';
import { CloseIcon } from '../../Shared/Components/SvgIcons';
import Image from "../../Shared/Primitive/Image"
import StorageService from '../../../App/StorageService';
import { RoleType, StorageKey } from '../../../App/enums';
import Button from '../../Shared/Primitive/Button';
import React, { useEffect } from 'react';
import { MediaLibraryFileDeleteRequest } from '../RoomServiceRequests/MediaLibraryRequests';
import { setModalData } from '../../Shared/SharedSlices/ModalSlice';
import SessionService from '../../../App/SessionService';
import { useToast } from '@chakra-ui/react'

function FileItemPreview(props: { isOrgAdmin: boolean; }) {
    const dispatch = useDispatch()
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const mediaLibraryState = useSelector((state: RootState) => state.MediaLibrarySlice)
    const orgId = SessionService.getItem(StorageKey.organizationId)
    const file = useSelector((state: RootState) => state.MediaLibrarySlice.selectedItem!)
    const creationDate = new Date(file.created_at || 0)
    const [canManageFile, setCanManageFile] = React.useState(false)
    const toast = useToast()

    useEffect(() => {
        if (file.room_id !== roomDataState.id) {
            dispatch(setMediaLibraryData({ ...mediaLibraryState, selectedItem: {} as MediaLibraryItem }))
        }
        switch (role) {
            case RoleType.ORGANIZATION:
                setCanManageFile(Number(orgId) === file.content_object.id || props.isOrgAdmin)
                break;
            case RoleType.USER:
                setCanManageFile(userDataState.id === file.content_object.id || props.isOrgAdmin)
                break;
            default:
                break;
        }
    }, [file])

    const onCopyLinkClick = () => {
        navigator.clipboard.writeText(window.location.host + '/f/' + file.room_id + '/' + file.id)
        toast({
            title: "Der Link wurde in die Zwischenablage kopiert.",
            status: "success",
            duration: 300000,
            isClosable: true,
            containerStyle: {
                fontFamily: 'Inter'}
        })
    }

    const onPreviewClose = () => {
        dispatch(setMediaLibraryData({ ...mediaLibraryState, selectedItem: {} as MediaLibraryItem }))
    }

    const onDownloadClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation();

        const accessToken = StorageService.getItem(StorageKey.accessToken) as string

        fetch('/media/' + file.id, {
            headers: {
                Authorization: 'Bearer ' + accessToken,
            },
        })
            .then(res => {
                if (res.status === 200) {
                    let blob = res.blob().then(blob => {
                        let objectUrl = window.URL.createObjectURL(blob);
                        let anchor = document.createElement("a");

                        anchor.href = objectUrl;
                        anchor.download = file.file_name || 'default_filename';
                        anchor.click();

                        window.URL.revokeObjectURL(objectUrl);
                    })
                }
            });
    }

    const onEditClick = () => {
        dispatch(setModalData({ open: true, editFileModal: true }))
    }

    const onDeleteClick = () => {
        const requestParams: [number, number?] = [file.id];
                    if (role === RoleType.ORGANIZATION) {
                        requestParams.push(Number(orgId!));
                    }

        MediaLibraryFileDeleteRequest(...requestParams).then(response => {
            if (response.status === 204) {
                let newItems = mediaLibraryState.items.filter(item => item.id !== file.id)
                dispatch(setMediaLibraryData({ ...mediaLibraryState, items: newItems, selectedItem: {} as MediaLibraryItem }))
            }
        })

    }

    return (
        <div className='media-library__preview-panel'>
            <div className='media-library__preview-panel__header'>
                <div onClick={onPreviewClose} className="media-library__close-icon">
                    <CloseIcon />
                </div>
            </div>

            {file ? (
                <div className='media-library__preview-panel-content'>
                    {file.file_type.startsWith('image/') ? (
                        <div className='media-library__image-preview-container'>
                            <Image
                                className="image image__file-preview"
                                src={'/media/' + file.id}
                                alt={file.name}
                            />
                        </div>
                    ) : (
                        <div className='media-library__image-preview-container'>
                            <img src="/document-icon.svg" alt="doc"></img>
                        </div>

                    )}

                    <div className='media-library__file-preview-button-container'>
                        <Button onClick={onDownloadClick}
                            className="button media-library__file-preview-button button--border-radius-05 button--color-green button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400 ">
                            Download</Button>

                        <Button onClick={onEditClick} disabled={!canManageFile}
                            className="button media-library__file-preview-button button--border-radius-05 button--color-gold button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400 ">
                            Bearbeiten</Button>

                        <Button onClick={onDeleteClick} disabled={!canManageFile}
                            className="button media-library__file-preview-button button--border-radius-05 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400 ">
                            Löschen</Button>
                    </div>

                    <div className='media-library__file-preview-info'>

                        <h3>{file.name}</h3>
                        <div className='media-library__file-preview-tags'>

                            {file.tags.map((tag, index) => (
                                <p className='profile-header__tag' key={index} style={{ display: 'inline' }}>{tag}</p>
                            ))}
                        </div>
                        <p>Mitglied: {file.content_type === 'user' ? file.content_object.first_name + ' ' + file.content_object.last_name : file.content_object.name}</p>
                        <p>Größe: {file.file_size < 1024 * 1024
                            ? (file.file_size / 1024).toFixed(2) + ' KB'
                            : (file.file_size / (1024 * 1024)).toFixed(2) + ' MB'}</p>
                        <p>Hochgeladen: {creationDate.toLocaleDateString()}</p>
                        <p>Link: <span 
                                className='media-library__copy-link'
                                onClick={() => onCopyLinkClick()} 
                                style={{ textDecoration: 'none', cursor: 'pointer' }}
                            > In die Zwischenablage kopieren
                            </span>
                        </p>
                        <p>Beschreibung: <span dangerouslySetInnerHTML={{ __html: file.description }} /></p>
                    </div>


                </div>
            ) : (
                <p>No file selected</p>
            )}
        </div>
    );
};

export default FileItemPreview;