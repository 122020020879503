function CharacterCounter(props: { current: number; max: number; modifier?: string; showWarning?: boolean }) {
    return (
        <p className={`character-counter character-counter--${props.modifier}`} style={{ color: props.current > props.max && props.showWarning ? 'red' : 'inherit' }}>
            {`${props.current}/${props.max}`}
            {props.showWarning && props.current > props.max && ` Der Inhalt überschreitet die zulässige Anzahl von ${props.max} Zeichen.`}
        </p>
    );
}

export default CharacterCounter
