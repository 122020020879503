import { useEffect, useState, useRef, MouseEventHandler } from "react"
import { RootState } from "../../../App/store"
import { useDispatch, useSelector } from "react-redux"
import EditorTextArea from "../../Shared/Components/Editor/EditorTextArea"
import { Editor as TinyMCEEditor } from "tinymce"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { EditorModifier } from "../../../App/enums"
import { CloseIcon, SendIcon, SmileIcon, FileIcon } from "../../Shared/Components/SvgIcons"
import Picker from "emoji-picker-react"
import CharacterCounter from "../../Shared/Components/CharacterCounter"
import HTTPService from "../../../App/HTTPService"
import { headers } from "../../../App/Axios"
import { ConversationMessage } from "../../../Types/Conversation"
import { eventDescriptionMaxLength } from "../../../App/GlobaleVariables"
import { socket } from "../../Shared/Header/Header"
import { userPutPrivacySettingsData } from "../../User/UserSettings/UserSettingsPrivacyRequest"
import { isBundle } from "typescript"

function ChatTextbox(props: { onSendMessage: (message: ConversationMessage) => void }) {
    const dispatch = useDispatch()
    const activeConversationId = useSelector((state: RootState) => state.chatSlice.activeConversationId)
    const conversations = useSelector((state: RootState) => state.chatSlice.conversations)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const [chatPartner, setChatPartner] = useState({ id: 0, user: { first_name: "", last_name: "", profile_photo: { id: 0, url_path: "" } } })
    const [inputState, setInputState] = useState<string>("")
    const [enterPressed,setEnterPressed] = useState(false)
    const [content, setContent] = useState<string>("")
    const [isBusy,setIsBusy] = useState<boolean>(false)
    const editorRef = useRef<TinyMCEEditor | null>(null)
    const translation = useSelector(selectTranslations)
    const contentMaxLength = 500
    const [isEmojiSelectorOpen, setIsEmojiSelectorOpen] = useState(false)
    let emojiRef = useRef() as React.MutableRefObject<HTMLInputElement>
    let inputRef = useRef() as React.MutableRefObject<HTMLInputElement>

    useEffect(() => {
        if (activeConversationId > 0) {
            let conversation = conversations.filter(conv => conv.id == activeConversationId)[0]
            setChatPartner(conversation.member.filter(userObj => userObj.user.id != userDataState.id)[0])
        }
    }, [activeConversationId])

    useEffect(() => {
        setInputState("")
        setContent("")
    }, [])

    useEffect(() => {
        setInputState("")
        setContent("")
    }, [activeConversationId])

    const onEmojiClick = (emojiObject: any,event?: { preventDefault: () => void }) => {
        event!.preventDefault()
        if (editorRef.current) {
            editorRef.current!.insertContent(emojiObject.emoji)
        }
    }

    useEffect(() => {
        let content_length = editorRef.current?.getContent({format: "text"}).length!
        if(enterPressed && content_length > 0){
            try {
                HTTPService.post("/conversation/" + activeConversationId + "/message", { content: editorRef.current?.getContent({format: "raw"}), conversation_id: activeConversationId }, headers).then(
                    response => {
                        if (response.status === 201) {
                            editorRef.current?.setContent("")
                            props.onSendMessage(response.data)
                        }
                    }
                )
            } catch (error: any) {
                if (error.response?.status === 400) {
                    //TODO Error handling
                }
            }
        }
        setEnterPressed(false)
    }, [enterPressed,activeConversationId,content])

    return (
        <div className="chat-layout__right-panel-input-container">
            <div className="chat-layout__photo-text-container">
                <EditorTextArea
                    inputState={inputState}
                    setInputState={setInputState}
                    editorRef={editorRef}
                    editorPlaceholder={translation.typeChatMessage}
                    setContent={setContent}
                    editorHeight={30}
                    modifier={EditorModifier.roomTextArea}
                    initialEditorValue=""
                    enterPressed={setEnterPressed}
                    isChat={true}
                />
            </div>
            <div className="chat-layout__right-panel-icons-container">
                <div onClick={() => setIsEmojiSelectorOpen(!isEmojiSelectorOpen)} className="chat-layout__right-panel-icon ">
                    <SmileIcon />
                </div>
                {isEmojiSelectorOpen && (
                    <div ref={emojiRef} className="chat-layout__emoji-picker">
                        <Picker skinTonesDisabled={false} onEmojiClick={onEmojiClick} />
                    </div>
                )}
                    <div className="chat-layout__right-panel-icon" onClick={() => setEnterPressed(true)}>
                        <SendIcon color="green" />
                    </div>
            </div>
        </div>
    )
}
export default ChatTextbox
