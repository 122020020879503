import React from "react"
import { useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import GoPremiumPackageInfoItem from "./GoPremiumPackageInfoItem"

function GoPremiumPackageInfo() {
    const translation = useSelector(selectTranslations)

    return (
        <div className="go-premium-package-info">
            <div className="go-premium-package-info__types-title-container">
                <div className="go-premium-package-info__types-title">
                    <div className="go-premium-package-info__title go-premium-package-info__title--font-size-22 go-premium-package-info__title--font-weight-700 go-premium-package-info__title--color-blue">
                        {translation.basic}
                    </div>
                    <div className="go-premium-package-info__title go-premium-package-info__title--color-grey go-premium-package-info__title--font-size-22">
                        |
                    </div>
                    <div className="go-premium-package-info__title go-premium-package-info__title--font-size-22 go-premium-package-info__title--font-weight-700 go-premium-package-info__title--color-gold">
                        {translation.premium}
                    </div>
                </div>
            </div>
            <GoPremiumPackageInfoItem text={translation.createYourCustomizablePage} onBasic={true} />
            <GoPremiumPackageInfoItem text={translation.showcaseYourIdeas} onBasic={true} />
            <GoPremiumPackageInfoItem text={translation.joinAndPartecipateInOtherRooms} onBasic={true} />
            <GoPremiumPackageInfoItem text={translation.createOnePrivate} onBasic={true} />
            <GoPremiumPackageInfoItem text={translation.displayYourPinboard} onBasic={false} />
            <GoPremiumPackageInfoItem text={translation.createUnlimitedRooms} onBasic={false} />
            <GoPremiumPackageInfoItem text={translation.use_media_library_basic} onBasic={true} onPremium={false} />
            <GoPremiumPackageInfoItem text={translation.use_media_library_premium} onBasic={false} />
        </div>
    )
}

export default GoPremiumPackageInfo
