import { RefObject, useEffect, useRef, useState } from "react"
import { RootState } from "../../../App/store"
import { useDispatch, useSelector } from "react-redux"
import { ConversationMessageGetRequest, chatListGetRequest } from "../ChatServiceRequest"
import ChatMessages from "./ChatMessages"
import { Conversation, ConversationMessage } from "../../../Types/Conversation"
import { LoadingAnimation } from "../../Shared/Components/SvgIcons"
import { resetUserPasswordConfirmError } from "../../User/UserSettings/UserSettingsSlice"
import { setConversationList, setHasUnseenMessages } from "../ChatSlice"
import { socket } from "../../Shared/Header/Header"

function ChatContent(props: { sentMsg: ConversationMessage | undefined }) {
    const activeConversationId = useSelector((state: RootState) => state.chatSlice.activeConversationId)
    const conversations = useSelector((state: RootState) => state.chatSlice.conversations)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const [chatPartner, setChatPartner] = useState({ id: 0, user: { first_name: "", last_name: "", profile_photo: { id: 0, url_path: "" } } })
    const [pageNum, setPageNum] = useState(1)
    const [hasNextPage, setHasNextPage] = useState(false)
    const [loadingConversation, setloadingConversation] = useState(true)
    const scrollContainer = useRef() as RefObject<HTMLDivElement>
    const [messages, setMessages] = useState<Array<ConversationMessage>>([])
    const bottom = useRef() as RefObject<HTMLDivElement>
    const container = useRef() as RefObject<HTMLDivElement>
    const dispatch = useDispatch()

    useEffect(() => {
        if (activeConversationId > 0) {
            setloadingConversation(true)
            let conversation = conversations.filter(conv => conv.id == activeConversationId)[0]
            setChatPartner(conversation.member.filter(userObj => userObj.user.id != userDataState.id)[0])

            const controller = new AbortController()
            const { signal } = controller
            ConversationMessageGetRequest(activeConversationId!, pageNum)
                .then(response => {
                    if (response.status === 200) {
                        setMessages(response.data.results.reverse())
                        setHasNextPage(Boolean(response.data.next))
                        setloadingConversation(false)
                        var topPos = bottom.current!.offsetTop
                        container.current!.scrollTop = topPos - 10
                        
                        let newList = conversations.map((item) =>  {
                            if(item.id == activeConversationId){
                                const updatedItem = {
                                    ...item,
                                    unseen_messages: 0,
                                  };
                          
                                  return updatedItem;
                            }
                
                            return item;
                        })
                        
                        dispatch(setConversationList(newList))
                        dispatch(setHasUnseenMessages(newList.filter((c:Conversation) => c.unseen_messages > 0).length > 0))
                    }
                })
                .catch(error => {
                    if (signal.aborted) return
                    console.log(error)
                })

            return () => {
                setPageNum(1)
                controller.abort()
            }
        }
    }, [activeConversationId])

    const onScroll = () => {
        if (scrollContainer.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollContainer.current
            if (Math.ceil(scrollTop * -1 + clientHeight + 1) >= scrollHeight && hasNextPage && !loadingConversation) {
                const controller = new AbortController()
                const { signal } = controller

                ConversationMessageGetRequest(activeConversationId!, pageNum + 1)
                    .then(response => {
                        if (response.status === 200) {
                            setMessages(response.data.results.reverse().concat(messages))
                            setHasNextPage(Boolean(response.data.next))
                            var topPos = bottom.current!.offsetTop
                            container.current!.scrollTop = topPos - 10
                            setPageNum(pageNum + 1)
                        }
                    })
                    .catch(error => {
                        if (signal.aborted) return
                        console.log(error)
                    })
                return () => controller.abort()
            }
        }
    }

    //update message status after messages are rendered
    useEffect(() => {
        let msgs_to_update = messages.filter(msg => msg.author.id != userDataState.id).map(msg => msg.id)
        if (msgs_to_update.length > 0 && socket && socket.readyState == socket.OPEN) {
            let data = { type: "msg.seen", messages: msgs_to_update }
            socket.send(JSON.stringify(data))

            dispatch(setHasUnseenMessages(conversations.filter((c:Conversation) => c.unseen_messages > 0).length > 0))
        }
    }, [messages])

    useEffect(() => {
        if (props.sentMsg!) {
            if (activeConversationId == props.sentMsg.conversation.id) {
                setMessages([...messages, props.sentMsg])
                setTimeout(() => {
                    var topPos = bottom.current!.offsetTop
                    container.current!.scrollTop = topPos - 10
                }, 10)
            }
        }
    }, [props.sentMsg])

    return (
        <div className="chat-layout__right-panel-content" ref={container}>
            <div className="chat-layout__msg-container" onScroll={onScroll} ref={scrollContainer}>
                <div style={{ width: "100%" }}>
                    {loadingConversation == true ? <LoadingAnimation type="cover" /> : <ChatMessages msgarray={messages} />}
                    <div style={{ float: "left", clear: "both" }} ref={bottom}></div>
                </div>
            </div>
        </div>
    )
}
export default ChatContent
