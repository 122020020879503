
import { useDispatch, useSelector } from 'react-redux';
import { MediaLibraryItem } from '../../../Types/MediaLibrary';
import { setMediaLibraryData } from '../../Shared/SharedSlices/MediaLibrarySlice';
import { RootState } from '../../../App/store';


function FileItem(props: { file: MediaLibraryItem }) {
    const dispatch = useDispatch()

    const mediaLibraryState = useSelector((state: RootState) => state.MediaLibrarySlice)
    const creationDate = new Date(props.file.created_at)
    const handleFileClick = (file: MediaLibraryItem) => {
        dispatch(setMediaLibraryData({ ...mediaLibraryState, selectedItem: file }))
    }

    return (
        <tr key={props.file.id} onClick={() => handleFileClick(props.file)}>
            <td >{props.file.name}</td>
            <td className='media-library__file-browser-table-col-hide-mobile'>{props.file.file_type.startsWith('image')? 'Bild' : 'Dokument'}</td>
            <td className='media-library__file-browser-table-col-hide-mobile'>{props.file.tags.map((tag, index) => (
                <p className='profile-header__tag' key={index} style={{ display: 'inline' }}>{tag}</p>
            ))}</td>
            <td className='media-library__file-browser-table-col-hide-mobile'> {props.file.content_type === 'user' ? props.file.content_object.first_name + ' ' + props.file.content_object.last_name: props.file.content_object.name }</td>
            <td className='media-library__file-browser-table-col-hide-mobile'>{creationDate.toLocaleString('de-de')}</td>
        </tr>
    );
};

export default FileItem;