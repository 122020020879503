import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { CloseIcon, TrashCanIconSmall } from "../../Shared/Components/SvgIcons"
import Button from "../../Shared/Primitive/Button"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { RootState } from "../../../App/store"
import { EditorModifier, RoleType, StorageKey } from "../../../App/enums"
import HTTPService from "../../../App/HTTPService"
import TextInput from "../../Shared/Primitive/TextInput"
import CharacterCounter from "../../Shared/Components/CharacterCounter"
import React, { useEffect, useRef } from "react"
import EditorTextArea from "../../Shared/Components/Editor/EditorTextArea"
import { Editor as TinyMCEEditor } from "tinymce"
import { htmlRegex } from "../../../App/Regex"
import SingleTextInput from "../../Shared/Components/SingleTextInput"
import HashtagInput from "../../Shared/Components/HashtagInput"
import { uploadFilePostRequest } from "../RoomServiceRequests/UploadFilePostRequest"
import { headers } from "../../../App/Axios"
import { setMediaLibraryData } from "../../Shared/SharedSlices/MediaLibrarySlice"
import SessionService from "../../../App/SessionService"

function RoomUploadModal() {
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const mediaLibraryState = useSelector((state: RootState) => state.MediaLibrarySlice)

    const roomId = roomDataState.id
    const translation = useSelector(selectTranslations)
    const [fileName, setFileName] = React.useState("")
    const [name, setName] = React.useState("")
    const [fileNameError, setFileNameError] = React.useState("")
    const dispatch = useDispatch()
    const [description, setDescription] = React.useState("")
    const [descriptionError, setDescriptionError] = React.useState("")
    const editorRef = useRef<TinyMCEEditor | null>(null)
    const textWithoutHtml = description!.replaceAll(htmlRegex, "")
    const [hashtags, setHashtags] = React.useState<string[]>([])
    const [fileselected, setFileSelected] = React.useState(false)
    const [file, setFile] = React.useState<any>(null)
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [serverError, setServerError] = React.useState("")
    const [isOrgAdmin, setIsOrgAdmin] = React.useState(false)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const orgId = SessionService.getItem(StorageKey.organizationId)


    const onSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        let formData = new FormData()

        setIsSubmitting(true)

        formData.append("content_type", "user")
        formData.append("room", roomId.toString())
        formData.append("file", file)
        formData.append("name", name)
        formData.append("file_name", fileName)
        formData.append("description", description)
        formData.append("tags", JSON.stringify(hashtags))

        if (role === RoleType.ORGANIZATION && orgId) {
            formData.append("content_type", "organization")
            formData.append("object_id", orgId)
        } else if (role === RoleType.USER) {
            formData.append("content_type", "user")

        }

        uploadFilePostRequest(formData).then((response) => {
            if (response.status === 201) {
                setIsSubmitting(false)
                dispatch(setMediaLibraryData({ ...mediaLibraryState, items: [...mediaLibraryState.items, response.data],selectedItem: response.data }))
                dispatch(setModalData({ open: false, roomUploadModal: false }))

            }
        }).catch((error) => {
            if(error.response.status === 400) {
                let msg = error.response.data[0]
                switch (msg) {
                    case "P_LIMIT_EXCEEDED":
                        setServerError(translation.premium_storage_limit_admin)
                        break
                    case "P_FS_LIMIT_EXCEEDED":
                        setServerError(translation.premiumFileLimitNotice)
                        break
                    case "NP_FS_LIMIT_EXCEEDED":
                        setServerError(isOrgAdmin ? translation.fileLimitNoticeAdmin : translation.fileLimitNoticeUser)
                        break
                    case "NP_LIMIT_EXCEEDED":
                        setServerError(isOrgAdmin? translation.non_premium_storage_limit_admin : translation.non_premium_storage_limit_user)
                        break
                }
            }else{
                setServerError("Ein unerwarteter Fehler ist aufgetreten.")
            }
            console.log(error)
        }).finally(() => {
            setIsSubmitting(false)
        })
    }

    const onModalClose = () => {
        dispatch(setModalData({ open: false, roomUploadModal: false }))
    }

    useEffect(() => {
        if (file !== null) {
            if (roomDataState.organization?.subscription_type === null || 0) {
                const fileSizeInMB = file.size / (1024 * 1024)
                if (fileSizeInMB > 10) {
                    setFileName("")
                    setFileSelected(false)
                    setFile(null)

                    let error = isOrgAdmin ? translation.fileLimitNoticeAdmin : translation.fileLimitNoticeUser
                    setServerError(error)
                    return
                }
            }else {
                const fileSizeInMB = file.size / (1024 * 1024)
                if (fileSizeInMB > 500) {
                    setFileName("")
                    setFileSelected(false)
                    setFile(null)

                    setServerError(translation.premiumFileLimitNotice)
                    return
                }
            }
        }
    }, [file, isOrgAdmin, roomDataState.organization?.subscription_type])

    useEffect(() => {
        HTTPService.get(`/role-in-org/${roomDataState.organization?.id}/`, headers).then(response => {
            if (response.status === 200) {
                setIsOrgAdmin(response.data.is_admin)
            }
        })
    }, [roomDataState.organization?.id])

    return (
        <div className="room-upload-modal">
            <div className="room-upload-modal__content">
                <div className="room-upload-modal__upper-panel">
                    <div className="room-upload-modal__title">{translation.uploadFile}</div>
                    <div onClick={onModalClose} className="room-upload-modal__close-icon">
                        <CloseIcon />
                    </div>
                </div>
                <div className="room-upload-modal__divider"></div>
                <div className="room-upload-modal__middle-panel">
                    <form className="room-upload-modal__room-input-container">
                        <SingleTextInput
                            textInput={<TextInput
                                type="text"
                                value={name}
                                textinputsize="small-40"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setName(event.target.value)
                                }}
                                placeholder={translation.name}
                                name="name"
                                autoComplete="off"
                                maxLength={30}
                            />}
                            errorText={""}
                            title={translation.name}

                        />

                        <div className="room-upload-modal__max-character-container">

                            <CharacterCounter current={fileName.length} max={30} />
                            <p className="room-upload-modal__error-text">{fileNameError}</p>
                        </div>

                        <div className="room-upload-modal__about-container">
                            <div className="room-upload-modal__max-character-container">
                                <div className="room-upload-modal__text-area">
                                    <div className="single-text-input__text">
                                        {translation.description}
                                    </div>
                                    <EditorTextArea
                                        inputState={description}
                                        setInputState={setDescription}
                                        editorRef={editorRef}
                                        editorPlaceholder={translation.description + " " + translation.optional}
                                        editorHeight={98}
                                        modifier={EditorModifier.editRoomDescription}
                                        initialEditorValue={''}
                                        setContent={() => { }}
                                        enterPressed={() => { }}
                                    />
                                </div>

                                <CharacterCounter current={textWithoutHtml.length} max={200} modifier="" />
                                <p className="room-upload-modal__error-text">{descriptionError}</p>
                            </div>
                        </div>

                        <SingleTextInput
                            title={translation.hashtags + " " + translation.optional}
                            textInput={<HashtagInput hashtags={hashtags} setHashtags={setHashtags} />}
                            errorText={""}
                        />

                        <div
                            className="room-upload-modal__file-upload"
                            onDrop={(event) => {
                                event.preventDefault()
                                if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
                                    const file = event.dataTransfer.files[0]
                                    setServerError("")
                                    setFileName(file.name)
                                    setFileSelected(true)
                                    const reader = new FileReader()
                                    reader.readAsDataURL(file)
                                    reader.addEventListener("load", () => {
                                        setFile(file)
                                    })
                                }
                            }}
                            onDragOver={(event) => event.preventDefault()}
                        >
                            <input
                                type="file"
                                id="file-upload"
                                style={{ display: "none" }}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    if (event.target.files && event.target.files.length > 0) {
                                        const file = event.target.files[0]
                                        setServerError("")
                                        setFileName(file.name)
                                        setFileSelected(true)

                                        const reader = new FileReader()
                                        reader.readAsDataURL(file)
                                        reader.addEventListener("load", () => {
                                            setFile(file)
                                        })
                                    }
                                }}
                            />
                            <label
                                htmlFor="file-upload"
                                className="room-upload-modal__file-upload-label"
                                style={{ display: "block", cursor: "pointer" }}
                            >
                                {fileselected ? fileName : translation.dragAndDropFile}
                            </label>
                            {fileselected && (
                                <div
                                    className="room-upload-modal__delete-file-button"
                                    onClick={() => {
                                        setFileName("")
                                        setFileSelected(false)
                                        setFile(null)
                                        setServerError("")
                                    }}
                                >
                                    <CloseIcon />
                                </div>
                            )}
                        </div>

                        <div className="room-upload-modal__button-container">
                            <div className="room-upload-modal__button">
                                <Button
                                    disabled={name.length > 0 && fileselected && isSubmitting === false ? false : true}
                                    onClick={onSubmit}
                                    type="submit"
                                    className="button button--font-size-small button--font-weight-400 button--text-color-white  button--color-gold  button--text-color-white button--color-red button--hover-brighter button--cursor-pointer button--border-radius-05 "
                                >
                                    {translation.uploadFile}
                                </Button>
                            </div>
                            <p className="room-upload-modal__error-text">{serverError}</p>

                        </div>
                    </form>
                </div>

            </div>
        </div>
    )
}

export default RoomUploadModal
