import { CheckedIconBlue, CheckedIconGold, UnCheckedIcon } from "../../Shared/Components/SvgIcons"

function GoPremiumPackageInfoItem(props: { text: string; onBasic?: boolean, onPremium?: boolean }) {
    const { text, onBasic, onPremium = true } = props;

    return (
        <div className="go-premium-package-info-item">
            <div className="go-premium-package-info-item__left-panel">
                <div className="go-premium-package-info-item__text-container">
                    <div className="go-premium-package-info-item__text">{text}</div>
                </div>
            </div>
            <div className="go-premium-package-info-item__right-panel">
                {onBasic ? (
                    <div className="go-premium-package-info-item__icon">
                        <CheckedIconBlue />
                    </div>
                ) : (
                    <div className="go-premium-package-info-item__icon">
                        <UnCheckedIcon />
                    </div>
                )}

                {onPremium ? (
                    <div className="go-premium-package-info-item__icon">
                        <CheckedIconGold />
                    </div>
                ) : (
                    <div className="go-premium-package-info-item__icon-unchecked">
                        <UnCheckedIcon />
                    </div>
                )}
            </div>
        </div>
    )
}

export default GoPremiumPackageInfoItem
