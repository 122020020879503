import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { LeftArrow } from "../Components/SvgIcons"
import Button from "../Primitive/Button"
import { RootState } from "../../../App/store"
import { useEffect, useState } from "react"
import { RoleType, StorageKey } from "../../../App/enums"
import SessionService from "../../../App/SessionService"

function MediaLibraryDirectLink() {
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const params = useParams()
    const navigate = useNavigate()


    useEffect(() => {
        switch (role){
            case RoleType.ORGANIZATION:
                const orgId = SessionService.getItem(StorageKey.organizationId)
                if(orgId){
                    navigate('/room/'+ orgId + '/' +params['room_id']+ '?tab=media&file='+params['file_id'])
                }                
                break
            case RoleType.USER:
                navigate('/room/'+params['room_id']+ '?tab=media&file='+params['file_id'])
                break
            default:
                break
        }
    },[])

    return (
        <></>
    )
}

export default MediaLibraryDirectLink
