import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"

/**
 * Sends a POST request to upload a file to the server.
 *
 * @param {FormData} data - The form data containing the file to be uploaded.
 * @returns {Promise<any>} - A promise that resolves to the response of the HTTP request.
 */
export const uploadFilePostRequest = (data: FormData) => {
    return HTTPService.post(`/upload-room-file/`, data, headers)
}