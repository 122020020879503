import React from "react"
import "../../../Styles/main.scss"
import Tooltip from "../Primitive/Tooltip"
import CharacterCounter from "./CharacterCounter"
import { TooltipQuestionmark } from "./SvgIcons"

type InputProps = {
    textInput: React.ReactElement
    title?: string
    errorText?: string
    hasTooltip?: boolean
    tooltipContent?: object | string
    current?: number
    max?: number
}

function SingleTextInput({ textInput, title, errorText, hasTooltip, tooltipContent, current, max }: InputProps) {
    return (
        <div className="single-text-input single-text-input--padding-left-2">
            <div className="single-text-input">
                <div className="single-text-input__text">
                    {title}{" "}
                    {hasTooltip && (
                        <div>
                            <Tooltip direction="settings-top" content={tooltipContent}>
                                <TooltipQuestionmark />
                            </Tooltip>
                        </div>
                    )}
                </div>

                {textInput}

                {current && max && (
                    <div className="single-text-input__text single-text-input__text--counter">
                        <CharacterCounter current={current} max={max} modifier="margin-right" />
                    </div>
                )}
            </div>
            <div className="single-text-input__error-text">{errorText}</div>
        </div>
    )
}

export default SingleTextInput
