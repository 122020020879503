import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { CloseIcon, TrashCanIconSmall } from "../../Shared/Components/SvgIcons"
import Button from "../../Shared/Primitive/Button"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { RootState } from "../../../App/store"
import { EditorModifier, RoleType, StorageKey } from "../../../App/enums"
import HTTPService from "../../../App/HTTPService"
import TextInput from "../../Shared/Primitive/TextInput"
import CharacterCounter from "../../Shared/Components/CharacterCounter"
import React, { useEffect, useRef } from "react"
import EditorTextArea from "../../Shared/Components/Editor/EditorTextArea"
import { Editor as TinyMCEEditor } from "tinymce"
import { htmlRegex } from "../../../App/Regex"
import SingleTextInput from "../../Shared/Components/SingleTextInput"
import HashtagInput from "../../Shared/Components/HashtagInput"
import { uploadFilePostRequest } from "../RoomServiceRequests/UploadFilePostRequest"
import { headers } from "../../../App/Axios"
import { setMediaLibraryData } from "../../Shared/SharedSlices/MediaLibrarySlice"
import { MediaLibraryFilePatchRequest } from "../RoomServiceRequests/MediaLibraryRequests"
import SessionService from "../../../App/SessionService"

function MediaLibraryEditFileModal() {
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const mediaLibraryState = useSelector((state: RootState) => state.MediaLibrarySlice)

    const roomId = roomDataState.id
    const translation = useSelector(selectTranslations)
    const [fileName, setFileName] = React.useState(mediaLibraryState.selectedItem!.file_name)
    const [name, setName] = React.useState(mediaLibraryState.selectedItem!.name)
    const [fileNameError, setFileNameError] = React.useState("")
    const dispatch = useDispatch()
    const [description, setDescription] = React.useState(mediaLibraryState.selectedItem?.description)
    const [descriptionError, setDescriptionError] = React.useState("")
    const editorRef = useRef<TinyMCEEditor | null>(null)
    const textWithoutHtml = description!.replaceAll(htmlRegex, "")
    const [hashtags, setHashtags] = React.useState<string[]>(mediaLibraryState.selectedItem?.tags || [])
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [serverError, setServerError] = React.useState("")
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const orgId = SessionService.getItem(StorageKey.organizationId)

    const onSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        let formData = new FormData()

        setIsSubmitting(true)


        formData.append("file_name", fileName)
        formData.append("name", name)
        formData.append("description", description || "")
        formData.append("tags", JSON.stringify(hashtags))

        if (role === RoleType.ORGANIZATION && orgId) {
            formData.append("content_type", "organization")
            formData.append("object_id", orgId)
        } else if (role === RoleType.USER) {
            formData.append("content_type", "user")

        }

        MediaLibraryFilePatchRequest(mediaLibraryState.selectedItem!.id, formData).then((response) => {
            if (response.status === 200) {
                setIsSubmitting(false)
                const updatedItems = mediaLibraryState.items.map(item =>
                    item.id === mediaLibraryState.selectedItem!.id ? response.data : item
                );
                dispatch(setMediaLibraryData({ ...mediaLibraryState, items: updatedItems, selectedItem: response.data }));
                dispatch(setModalData({ open: false, editFileModal: false }))
            }
        }).catch((error) => {
            setServerError("Error uploading file")
            console.log(error)
        }).finally(() => {
            setIsSubmitting(false)
        })
    }

    const onModalClose = () => {
        dispatch(setModalData({ open: false, roomUploadModal: false }))
    }

    return (
        <div className="room-upload-modal">
            <div className="room-upload-modal__content">
                <div className="room-upload-modal__upper-panel">
                    <div className="room-upload-modal__title">{translation.editFile}</div>
                    <div onClick={onModalClose} className="room-upload-modal__close-icon">
                        <CloseIcon />
                    </div>
                </div>
                <div className="room-upload-modal__divider"></div>
                <div className="room-upload-modal__middle-panel">
                    <form className="room-upload-modal__room-input-container">
                        <SingleTextInput
                            textInput={<TextInput
                                type="text"
                                value={name}
                                textinputsize="small-40"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setName(event.target.value)
                                }}
                                placeholder={translation.name}
                                name="name"
                                autoComplete="off"
                                maxLength={30}
                            />}
                            errorText={""}
                            title={translation.name}

                        />

                        <div className="room-upload-modal__max-character-container">

                            <CharacterCounter current={fileName.length} max={30} />
                            <p className="room-upload-modal__error-text">{fileNameError}</p>
                        </div>

                        <div className="room-upload-modal__about-container">
                            <div className="room-upload-modal__max-character-container">
                                <div className="room-upload-modal__text-area">
                                    <div className="single-text-input__text">
                                        {translation.description}
                                    </div>
                                    <EditorTextArea
                                        inputState={description || ""}
                                        setInputState={setDescription}
                                        editorRef={editorRef}
                                        editorPlaceholder={translation.description + " " + translation.optional}
                                        editorHeight={98}
                                        modifier={EditorModifier.editRoomDescription}
                                        initialEditorValue={description || ""}
                                        setContent={() => { }}
                                        enterPressed={() => { }}
                                    />
                                </div>

                                <CharacterCounter current={textWithoutHtml.length} max={200} modifier="" />
                                <p className="room-upload-modal__error-text">{descriptionError}</p>
                            </div>
                        </div>

                        <SingleTextInput
                            title={translation.hashtags + " " + translation.optional}
                            textInput={<HashtagInput hashtags={hashtags} setHashtags={setHashtags} />}
                            errorText={""}

                        />

                        <div className="room-upload-modal__button-container">
                            <div className="room-upload-modal__button">
                                <Button
                                    disabled={fileName.length > 0 && isSubmitting === false ? false : true}
                                    onClick={onSubmit}
                                    type="submit"
                                    className="button button--font-size-small button--font-weight-400 button--text-color-white  button--color-gold  button--text-color-white button--color-red button--hover-brighter button--cursor-pointer button--border-radius-05 "
                                >
                                    {translation.save}
                                </Button>
                            </div>
                            <p className="room-upload-modal__error-text">{serverError}</p>

                        </div>
                    </form>
                </div>

            </div>
        </div>
    )
}

export default MediaLibraryEditFileModal
