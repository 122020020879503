import { useRef } from "react"
import { useSelector } from "react-redux"
import { eventDescriptionMaxLength, eventTitleMaxLength, profileDefault, roomDefaultPhoto } from "../../../App/GlobaleVariables"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import "../../../Styles/main.scss"
import Button from "../Primitive/Button"
import Checkbox from "../Primitive/Checkbox"
import Image from "../Primitive/Image"
import TextInput from "../Primitive/TextInput"
import CharacterCounter from "./CharacterCounter"
import { CloseIcon, RedBinIcon, Upload } from "./SvgIcons"
import { Editor as TinyMCEEditor } from "tinymce"
import EditorTextArea from "./Editor/EditorTextArea"
import { RootState } from "../../../App/store"
import { htmlRegex } from "../../../App/Regex"
import { EditorModifier } from "../../../App/enums"

interface RoomModalProps {
    title: string
    hasDeleteOption: boolean
    onModalClose: () => void
    isPublicRoom: boolean
    isPrivateRoom: boolean
    onRoomTypeSelected: () => void
    roomName: string
    setRoomName: Function
    descriptionError: string
    detailError: string
    roomNameError: string
    description: string
    setDescription: Function
    roomManager?: string
    roomManagerPhoto?: string
    photo?: any
    onPhotoChange?: any
    previewUrl?: string
    onCheckboxChange: any
    allow_requests: boolean
    only_admins_can_upload: boolean
    invite_others: boolean
    share_documents: boolean
    onFormSubmit?: any
    onDeleteButtonClick?: any
    buttonText: string
    photoSizeError: boolean,
    roomJoinQuestion: string,
    setRoomJoinQuestion: Function,
    roomJoinQuestionError: string
}

function RoomModal({
    title,
    hasDeleteOption,
    onModalClose,
    isPublicRoom,
    isPrivateRoom,
    onRoomTypeSelected,
    roomName,
    setRoomName,
    roomNameError,
    description,
    setDescription,
    descriptionError,
    detailError,
    roomManager,
    roomManagerPhoto,
    photo,
    onPhotoChange,
    previewUrl,
    onCheckboxChange,
    allow_requests,
    invite_others,
    share_documents,
    only_admins_can_upload,
    onFormSubmit,
    onDeleteButtonClick,
    buttonText,
    photoSizeError,
    roomJoinQuestion,
    setRoomJoinQuestion,
    roomJoinQuestionError
}: RoomModalProps) {
    const translation = useSelector(selectTranslations)
    const editorRef = useRef<TinyMCEEditor | null>(null)
    const questionEditorRef = useRef<TinyMCEEditor | null>(null)
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const textWithoutHtml = description!.replaceAll(htmlRegex, "")
    let regex = /(?:#([\w\u00C0-\u017F]+)|<span[^>]*\bclass="hashtag"[^>]*>([\w\u00C0-\u017F]+)<\/span>)/gi

    const replacedText =
        title === translation.editRoom
            ? roomDataState!.description!.replace(regex, (match, group1, group2) => {
                  const hashtag = group1 || group2
                  const matchedHashtag = roomDataState.hashtags?.find(h => h.replace("&nbsp;", "") === hashtag.replace("#", ""))

                  if (matchedHashtag) {
                      const link = `<span class="hashtag" contenteditable="false">${hashtag.startsWith("#") ? hashtag : "#" + hashtag}</span>`
                      return link
                  } else {
                      return match
                  }
              })
            : ""

    return (
        <div className="create-room-modal" onSubmit={onFormSubmit}>
            <div className="create-room-modal__title-container">
                <div className="create-room-modal__title">{title}</div>
                <div onClick={onModalClose} className="create-room-modal__close-icon">
                    <CloseIcon />
                </div>
            </div>
            <form className="create-room-modal__content">
                <div className="create-room-modal__form-first-row">
                    <div className="create-room-modal__room-input-container">
                        <div className="create-room-modal__small-text">{translation.roomName}</div>
                        <div className="organization-profile-add-event__max-character-container">
                            <TextInput
                                type="text"
                                value={roomName}
                                textinputsize="small-40"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setRoomName(event.target.value)
                                }}
                                placeholder={translation.name}
                                name="roomName"
                                autoComplete="off"
                                maxLength={50}
                            />
                            <CharacterCounter current={roomName.length} max={eventTitleMaxLength} modifier="margin-right" />
                            <p className="create-room-modal__error-text">{roomNameError}</p>
                        </div>
                    </div>
                    <div className="create-room-modal__select-type-container">
                        <div className="create-room-modal__small-text">{translation.select}</div>
                        <div className="create-room-modal__types">
                            <div
                                onClick={onRoomTypeSelected}
                                className={`create-room-modal__type create-room-modal__type--background-color-blue ${
                                    isPublicRoom && "create-room-modal__type--selected"
                                }`}
                            >
                                {translation.public}
                            </div>
                            <div
                                onClick={onRoomTypeSelected}
                                className={`create-room-modal__type create-room-modal__type--background-color-gold ${
                                    isPrivateRoom && "create-room-modal__type--selected"
                                }`}
                            >
                                {translation.private}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="create-room-modal__about-container">
                    <div className="create-room-modal__small-text">{translation.description}</div>
                    <div className="create-room-modal__max-character-container">
                        <div className="create-room-modal__text-area">
                            <EditorTextArea
                                inputState={description}
                                setInputState={setDescription}
                                editorRef={editorRef}
                                editorPlaceholder={translation.description}
                                editorHeight={98}
                                modifier={EditorModifier.editRoomDescription}
                                initialEditorValue={replacedText!}
                                setContent={() => {}}
                                enterPressed={() => {}}
                            />
                        </div>

                        <CharacterCounter current={textWithoutHtml.length} max={eventDescriptionMaxLength} modifier="room-description" />
                        <p className="create-room-modal__error-text">{descriptionError}</p>
                    </div>
                </div>
                <div className="create-room-modal__image-section">
                    <div className="create-room-modal__small-text">{translation.addPhoto}</div>
                    <div className="create-room-modal__image-container">
                        {}{" "}
                        <Image
                            className={`create-room-modal__image ${hasDeleteOption ? "create-room-modal__image--round" : "create-room-modal__image--square"}`}
                            src={previewUrl || roomDefaultPhoto}
                            alt="room"
                        />
                        <div className="create-room-modal__add-photo">
                            <input className="create-room-modal__input-file" type="file" accept="image/*" onChange={onPhotoChange} />
                            <Upload />
                        </div>
                        <div className="create-room-modal__text create-room-modal__text--display-none">
                            {translation.uploadPhoto} <br /> {translation.maxImageSize}
                        </div>
                    </div>
                    {photoSizeError && <p className="create-room-modal__error-text">{translation.fileIsTooBig}</p>}
                </div>
                <div className="create-room-modal__manager-section">
                    <div className="create-room-modal__small-text"> {translation.roomManager} </div>
                    <div className="create-room-modal__manager-container">
                        <Image src={roomManagerPhoto || profileDefault} className="image__profile image__profile--size-small  image__profile--fit" />
                        <div className="create-room-modal__text create-room-modal__text--margin-left-1">{roomManager}</div>
                    </div>
                </div>

                {isPrivateRoom && (
                    <div className="create-room-modal__permissions-section">
                        <div className="create-room-modal__small-text"> {translation.permissions}</div>
                        <div className="create-room-modal__checkbox-container">
                            <Checkbox color="dark-blue" name="share_documents" checked={share_documents} onChange={onCheckboxChange} />
                            <div className="create-room-modal__text create-room-modal__text--margin-left-1">
                                {translation.allowMembersToChatAndShareDocuments}
                            </div>
                        </div>

                        <div className="create-room-modal__checkbox-container">
                            <Checkbox color="dark-blue" name="invite_others" checked={invite_others} onChange={onCheckboxChange} />
                            <div className="create-room-modal__text create-room-modal__text--margin-left-1">{translation.allowMembersToInviteOtherMembers}</div>
                        </div>

                        <div className="create-room-modal__checkbox-container">
                            <Checkbox color="dark-blue" name="allow_requests" checked={allow_requests} onChange={onCheckboxChange} />
                            <div className="create-room-modal__text create-room-modal__text--margin-left-1">{translation.allowMembersRequestToJoinTheRoom}</div>
                        </div>
                        
                        <div className="create-room-modal__checkbox-container">
                            <Checkbox color="dark-blue" name="only_admins_can_upload" checked={only_admins_can_upload} onChange={onCheckboxChange} />
                            <div className="create-room-modal__text create-room-modal__text--margin-left-1">{translation.onlyAdminsCanUpload}</div>
                        </div>

                        <div className="create-room-modal__room-input-container create-room-modal__margin-top-15">
                            <div className="create-room-modal__small-text">{translation.rommJoinQuestion}</div>
                            <div className="organization-profile-add-event__max-character-container">
                            <div className="create-room-modal__text-area">
                            <EditorTextArea
                                inputState={roomJoinQuestion}
                                setInputState={setRoomJoinQuestion}
                                editorRef={questionEditorRef}
                                editorPlaceholder={translation.rommJoinQuestion}
                                editorHeight={98}
                                modifier={EditorModifier.editRoomDescription}
                                initialEditorValue={title === translation.editRoom?roomDataState.join_question:""}
                                setContent={() => {}}
                                enterPressed={() => {}}
                            />
                        </div>
                                {/*<CharacterCounter current={roomName.length} max={100} modifier="margin-right" />*/}
                                <p className="create-room-modal__error-text">{roomJoinQuestionError}</p>
                            </div>
                        </div>
                    </div>
                )}
                {hasDeleteOption && (
                    <div>
                        <div className="create-room-modal__small-title-delete"> {translation.deleteRoom}</div>
                        <div className="create-room-modal__delete-room-container" onClick={onDeleteButtonClick}>
                            <div className="create-room-modal__delete-icon">
                                <RedBinIcon />
                            </div>
                            <div className="create-room-modal__delete-container">
                                <div className="create-room-modal__delete-text">{translation.deleteThisRoom}</div>
                                <p className="create-room-modal__delete-subtext"> {translation.deletingThisRoomWillLoseAllAssets} </p>
                            </div>
                        </div>
                    </div>
                )}
                <p className="create-room-modal__error-text create-room-modal__error-text--full-width">{detailError}</p>

                <div className={`create-room-modal__button-container ${isPublicRoom && "create-room-modal__button-container--margin-top"}`}>
                    <div className="create-room-modal__button">
                        <Button
                            type="submit"
                            className="button button--font-size-small button--font-weight-400 button--text-color-white button--color-gold button--hover-brighter button--cursor-pointer button--border-radius-05"
                        >
                            {buttonText}
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default RoomModal
