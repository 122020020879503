import React, { RefObject, useEffect, useRef, useState } from 'react';
import Checkbox from '../../Shared/Primitive/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { setModalData } from '../../Shared/SharedSlices/ModalSlice';
import Button from '../../Shared/Primitive/Button';
import { selectTranslations } from '../../../App/i18n/i18nSlice';
import { RootState } from '../../../App/store';
import HTTPService from '../../../App/HTTPService';
import { headers } from '../../../App/Axios';
import { MediaLibraryItem } from '../../../Types/MediaLibrary';
import { MediaLibraryFilesGetRequest } from '../RoomServiceRequests/MediaLibraryRequests';
import MediaLibrary from '../Layouts/MediaLibrary';
import FileItem from './FileItem';
import { setMediaLibraryData } from '../../Shared/SharedSlices/MediaLibrarySlice';
import { LoadingAnimation } from '../../Shared/Components/SvgIcons';
import SessionService from '../../../App/SessionService';
import { RoleType, StorageKey } from '../../../App/enums';
import { useSearchParams } from 'react-router-dom';
import { init } from 'linkifyjs';
import { Stack, Skeleton } from '@chakra-ui/react';


function FileBrowser(props: { isOrgAdmin: boolean; }) {
    const dispatch = useDispatch()
    const translation = useSelector(selectTranslations)
    const [searchParams, setSearchParams] = useSearchParams()
    const mediaLibraryState = useSelector((state: RootState) => state.MediaLibrarySlice)
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const [pageNum, setPageNum] = useState(1)
    const [hasNextPage, setHasNextPage] = useState(false)
    const scrollContainer = useRef() as RefObject<HTMLDivElement>
    const [loadingFiles, setLoadingFiles] = useState(false)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const orgId = SessionService.getItem(StorageKey.organizationId)
    const [intialLoadDone, setInitialLoadDone] = useState(false)

    useEffect(() => {
        if (roomDataState.id > 0) {
            setLoadingFiles(true)
            dispatch(setMediaLibraryData({ ...mediaLibraryState, selectedItem: {} as MediaLibraryItem }))
            const requestParams: [number, number, string, boolean, boolean, number?] = [roomDataState.id, pageNum, mediaLibraryState.filter.query, mediaLibraryState.filter.documents, mediaLibraryState.filter.images];
            if (role === RoleType.ORGANIZATION) {
                requestParams.push(orgId!);
            }
            MediaLibraryFilesGetRequest(...requestParams)
                .then(response => {
                    if (response.status === 200) {
                        dispatch(setMediaLibraryData({ ...mediaLibraryState, items: response.data.results }))
                        setHasNextPage(Boolean(response.data.next))
                        setInitialLoadDone(true)
                    }
                    setLoadingFiles(false)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [roomDataState.id])

    useEffect(() => {
        if (intialLoadDone && mediaLibraryState.items.length > 0 && searchParams.get('file') === null) {
            dispatch(setMediaLibraryData({ ...mediaLibraryState, selectedItem: mediaLibraryState.items[0] }))
        }
    }, [intialLoadDone])

    useEffect(() => {
        if (mediaLibraryState.selectedItem?.id) {
            searchParams.set('file', mediaLibraryState.selectedItem!.id.toString())
            setSearchParams(searchParams)
        }
    }, [mediaLibraryState.selectedItem])

    useEffect(() => {

        const fileParam = searchParams.get('file');
        if (fileParam && !isNaN(Number(fileParam))) {
            const fileId = Number(fileParam);
            const selectedItem = mediaLibraryState.items.find(item => item.id === fileId);
            if (selectedItem) {
                dispatch(setMediaLibraryData({ ...mediaLibraryState, selectedItem: selectedItem }))
            }
        }
    }, [mediaLibraryState.items])

    useEffect(() => {
        if (loadingFiles || roomDataState.id === undefined) return
        const handler = setTimeout(() => {
            setLoadingFiles(true)
            setPageNum(1)
            const requestParams: [number, number, string, boolean, boolean, number?] = [roomDataState.id, pageNum, mediaLibraryState.filter.query, mediaLibraryState.filter.documents, mediaLibraryState.filter.images];
            if (role === RoleType.ORGANIZATION) {
                requestParams.push(orgId!);
            }
            MediaLibraryFilesGetRequest(...requestParams)
                .then(response => {
                    if (response.status === 200) {
                        dispatch(setMediaLibraryData({ ...mediaLibraryState, items: response.data.results }))
                        setHasNextPage(Boolean(response.data.next))
                    }
                    setLoadingFiles(false)
                })
                .catch(error => {
                    console.log(error)
                })
        }, 300)

        return () => {
            clearTimeout(handler)
        }
    }, [mediaLibraryState.filter])

    const onScroll = () => {
        if (scrollContainer.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollContainer.current

            if (Math.ceil(scrollTop + clientHeight + 1) >= scrollHeight && hasNextPage && !loadingFiles) {
                const controller = new AbortController()
                const { signal } = controller

                setLoadingFiles(true)

                const requestParams: [number, number, string, boolean, boolean, number?] = [roomDataState.id, pageNum, mediaLibraryState.filter.query, mediaLibraryState.filter.documents, mediaLibraryState.filter.images];
                if (role === RoleType.ORGANIZATION) {
                    requestParams.push(orgId!);
                }
                MediaLibraryFilesGetRequest(...requestParams)

                    .then(response => {
                        setLoadingFiles(false)
                        if (response.status === 200) {
                            dispatch(setMediaLibraryData({ ...mediaLibraryState, items: [...mediaLibraryState.items, ...response.data.results] }))
                            setHasNextPage(Boolean(response.data.next))
                            setPageNum(pageNum + 1)
                        }
                    })
                    .catch(error => {
                        if (signal.aborted) return
                        console.log(error)
                    })
                return () => controller.abort()
            }
        }
    }

    return (
        <div className={`media-library__file-browser ${mediaLibraryState.selectedItem!.id ? 'media-library__file-browser-preview-active' : ''}`} ref={scrollContainer} onScroll={onScroll}>
            <table className='media-library__file-browser-table'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th className='media-library__file-browser-table-col-hide-mobile'>Typ</th>
                        <th className='media-library__file-browser-table-col-hide-mobile'>Tags</th>
                        <th className='media-library__file-browser-table-col-hide-mobile'>Mitglied</th>
                        <th className='media-library__file-browser-table-col-hide-mobile'>Hochgeladen</th>
                    </tr>
                </thead>
                <tbody>
                    {!loadingFiles && mediaLibraryState.items.length > 0 && (
                        mediaLibraryState.items.map((file, index) => {
                            return <FileItem key={index} file={file} />
                        })
                    )}
                    {loadingFiles && (

                        <tr>
                            <td colSpan={5} style={{ textAlign: 'center' }}><Stack>
                                <Skeleton height='20px' />
                                <Skeleton height='20px' />
                                <Skeleton height='20px' />
                            </Stack></td>
                        </tr>
                    )}
                    {mediaLibraryState.items.length === 0 && !loadingFiles && (
                        <tr>
                            <td colSpan={5} style={{ textAlign: 'center' }}>{translation.noFiles}</td>
                        </tr>
                    )}
                    <tr style={{ height: '100%' }}></tr>
                </tbody>
            </table>

        </div>
    );
};

export default FileBrowser;