import { useDispatch } from "react-redux"
import DOMPurify from "dompurify"
import { green } from "../../../../App/GlobaleVariables"
import { CommentIcon, CopyIcon, EditProfileIcon, ShareIcon } from "../../../Shared/Components/SvgIcons"
import Image from "../../../Shared/Primitive/Image"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import { setPinboardId } from "../OrganizationProfilePinboardSlice"
import { htmlRegex } from "../../../../App/Regex"
import { replaceHTMLCharactersWithCorrespondingLetters } from "../../../../App/Helpers/Helpers"
import { useEffect,useState } from "react"

function OrganizationProfilePinboard(props: {
    profile_photo: string
    organization_name: string
    title: string
    photo?: string
    description?: string
    id: number
    showEdit: boolean
    commentsCount: number | undefined
    hashtags?: Array<{ text: string }>

    onClick: () => void
}) {
    const dispatch = useDispatch()
    const onInviteLinkModalOpen = (id: number) => {
        dispatch(setModalData({ open: true, eventLinkModal: true }))
        dispatch(setPinboardId({ id: id, isYourOrganization: props.showEdit }))
    }

    const onCommentsModalOpen = (id: number) => {
        dispatch(setModalData({ open: true, pinboardCommentsModal: true }))
        dispatch(setPinboardId({ id: id, isYourOrganization: props.showEdit }))
    }

    const onShareEventToRoomModalOpen = (id: number) => {
        dispatch(setModalData({ open: true, shareEventToRoomModal: true }))
        dispatch(setPinboardId({ id: id, isYourOrganization: props.showEdit }))
    }

    const [parsedDescription,setParsedDescription] = useState("")

    useEffect(() => {
        if(props!.description){
            let cleanHtml = DOMPurify.sanitize(props.description, {
                ALLOWED_TAGS: ["b", "em","strong", "p", "span", "a", "div", "img", "header", "footer","ul","ol","li","br"],
                ALLOWED_ATTR: ["class", "href", "target", "data-type", "className", "id", "rel", "src", "style", "width", "type", "data-reactroot"],
            })

            cleanHtml = replaceHTMLCharactersWithCorrespondingLetters(
                cleanHtml.replaceAll(/&nbsp;/g, " ")
                    .replace(htmlRegex, "")
                    .replaceAll(/&nbsp/g, " "))

            setParsedDescription(cleanHtml.replaceAll(htmlRegex, ""))
        }
    },[props!.description])

    return (
        <div className="organization-profile-pinboard__content">
            <div
                className="organization-profile-pinboard__organization-container"
                onClick={props.showEdit ? props.onClick : () => onCommentsModalOpen(props.id)}
            >
                <Image src={props.profile_photo} className="image image__profile image__profile--pinboard-size-small" />
                <div className="organization-profile-pinboard__name">{props.organization_name}</div>
                {props.showEdit && (
                    <span onClick={props.showEdit ? props.onClick : () => onCommentsModalOpen(props.id)} className="organization-profile-pinboard__edit">
                        <EditProfileIcon modifier="small" color={green} />
                    </span>
                )}
            </div>
            <div className="organization-profile-pinboard__title-container" onClick={props.showEdit ? props.onClick : () => onCommentsModalOpen(props.id)}>
                <div className="organization-profile-pinboard__title"> {props.title}</div>
            </div>
            {props.photo ? (
                <div className="organization-profile-pinboard__event-content" onClick={props.showEdit ? props.onClick : () => onCommentsModalOpen(props.id)}>
                    <div className="organization-profile-pinboard__text" dangerouslySetInnerHTML={{ __html: parsedDescription }}>
                    </div>
                    <div className="organization-profile-pinboard__event-image">
                        <Image src={props.photo} className="organization-profile-pinboard__image" />
                    </div>
                </div>
            ) : (
                <div 
                dangerouslySetInnerHTML={{ __html: parsedDescription }} className="organization-profile-pinboard__description" onClick={props.showEdit ? props.onClick : () => onCommentsModalOpen(props.id)}>
                </div>
            )}

            <div className="organization-profile-pinboard__icons">
                <div
                    className={`organization-profile-pinboard__edit ${
                        props?.commentsCount !== undefined && props?.commentsCount > 0 && "organization-profile-pinboard__edit--hover"
                    }`}
                    onClick={() => onCommentsModalOpen(props.id)}
                >
                    <CommentIcon />
                    {props?.commentsCount !== undefined && props?.commentsCount > 0 && (
                        <div className="organization-profile-pinboard__numberBox" onClick={() => onCommentsModalOpen(props.id)}>
                            <div className="organization-profile-pinboard__number"> {props?.commentsCount}</div>
                        </div>
                    )}
                </div>
                {props.showEdit && (
                    <div className="organization-profile-pinboard__edit" onClick={() => onShareEventToRoomModalOpen(props.id)}>
                        <ShareIcon color="grey" strokeWidth="3" size="size-big" />
                    </div>
                )}
                <div className="organization-profile-pinboard__edit" onClick={() => onInviteLinkModalOpen(props.id)}>
                    <CopyIcon />
                </div>
            </div>
        </div>
    )
}

export default OrganizationProfilePinboard
