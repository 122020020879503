import { formatDate } from "../../../App/Helpers/Helpers"
import { useSelector } from "react-redux"
import { RootState } from "../../../App/store"
import { ConversationMessage } from "../../../Types/Conversation"
import DOMPurify from "dompurify"
import { useState } from "react"
import { parse } from "path"

function ChatMessages(props: { msgarray: Array<ConversationMessage> }) {
    const [lastDate,setLastDate] = useState("")

    return (
        <div className="chat-content">
            {props.msgarray?.map((msg,idx) => {
                let showDate = false
                if(idx == 0){
                    showDate = true
                }

                if(idx > 0 ){
                    let previous_msg_created_at = new Date(props.msgarray[idx-1].created_at)
                    let current_msg_created_at = new Date(msg.created_at)

                    if(previous_msg_created_at.getDate() < current_msg_created_at.getDate()){
                        showDate = true
                    }
                }
                return <ChatMessage key={msg.id} message={msg} showDate={showDate}  />
            },)}
        </div>
    )
}

export default ChatMessages

type IProps = {
    message: ConversationMessage,
    showDate: boolean
}

function ChatMessage({ message,showDate }: IProps) {
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const message_content = DOMPurify.sanitize(message.content, {
        ALLOWED_TAGS: ["b","br", "em","strong", "p", "span", "a", "div", "img", "header", "footer","ul","ol","li","br"],
        ALLOWED_ATTR: ["class", "href", "target", "data-type", "className", "id", "rel", "src", "style", "width", "type", "data-reactroot"],
    })
    const parsedDate = new Date(message.created_at)
    return (
        <div className={`chat-message`} id={`${message.id}`}>
            {showDate && (
                <div className="chat-message__date">
                    <span> {parsedDate.getDate()} {parsedDate.toLocaleString('de-de',{month: "short"})}</span>
                </div>
            )}

            {showDate && (
                <div className="chat-message__break"></div>
            )}

            {message.author.id != userDataState.id ? (
                <div className="chat-message__left-panel">
                    <div className={`chat-message__post-content chat-message__post-content__left`}>
                        <div
                            title={`${message.author.first_name} ${message.author.last_name}, ${formatDate(message.created_at, "HH:mm dd.MM.yy")}`}
                            className={`chat-message__post-text-container`}
                        >
                            <div className={`chat-message__post-text`}>
                                {<div className="chat-message-text chat-message-text__left" dangerouslySetInnerHTML={{ __html: message_content }}></div>}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="chat-message__right-panel">
                    <div className={`chat-message__post-content chat-message__post-content__right`}>
                        <div
                            title={`${message.author.first_name} ${message.author.last_name}, ${formatDate(message.created_at, "HH:mm dd.MM.yy")}`}
                            className={`chat-message__post-text-container `}
                        >
                            <div className={`chat-message__post-text`}>
                                {<div className="chat-message-text chat-message-text__right" dangerouslySetInnerHTML={{ __html: message_content }}></div>}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
